import React from 'react';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';

import { App } from './App';

declare global {
  interface Window {
    ENV: {
      SENTRY_DSN: string;
      RELEASE: string;
      VERSION: string;
      HASH: string;
    };
  }
}

Sentry.init({
  dsn: window.ENV.SENTRY_DSN,
  release: window.ENV.RELEASE,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.extraErrorDataIntegration(),
    Sentry.sessionTimingIntegration(),
    Sentry.captureConsoleIntegration(),
    Sentry.httpClientIntegration(),
  ],
  profilesSampleRate: 0.01,
  // Performance Monitoring
  tracesSampleRate: 0.01, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootDomElement = document.getElementById('root');

if (rootDomElement) {
  const root = createRoot(rootDomElement);
  root.render(<App />);
}
