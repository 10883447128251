import React from 'react';

import { ReactComponent as LogoSVG } from './logo.svg';
import { ReactComponent as LogoGraySVG } from './logoGray.svg';

import { classNames } from '../../../../domain/utils/styles';

type TSize = 'small' | 'medium' | 'large';

interface IProps {
  size?: TSize;
  isGray?: boolean;
  isRounded?: boolean;
  className?: string;
}

const sizeToStyle: Record<TSize, string> = {
  small: 'w-14 h-14',
  medium: 'w-24 h-24',
  large: 'w-72 h-72',
};

export const Logo: React.FC<IProps> = function ({ isGray, isRounded, size = 'medium', className }) {
  const classNamesComposed = classNames([sizeToStyle[size], isRounded ? 'rounded-full' : '', className]);

  if (isGray) {
    return <LogoGraySVG className={classNamesComposed} />;
  }

  return <LogoSVG className={classNamesComposed} />;
};
