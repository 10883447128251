import React from 'react';

import { classNames } from '../../../domain/utils/styles';
import { Logo } from './Logo/Logo';

interface IProps {
  isFullScreen?: boolean;
  hasBackdrop?: boolean;
}

export const Loader: React.FC<IProps> = function ({ isFullScreen, hasBackdrop }) {
  return (
    <span
      className={classNames([
        'flex text-white items-center justify-center',
        isFullScreen && 'absolute w-screen h-screen ',
        !isFullScreen && 'relative w-full h-full',
        isFullScreen && hasBackdrop && 'bg-black',
      ])}
    >
      <span className="animate-pulse">
        <Logo isGray />
      </span>
    </span>
  );
};
