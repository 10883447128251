import { RouterProvider } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import './index.css';

import { composeProviders } from './domain/infrastructure/composeProviders';
import { router } from './Routes';

export const App = function () {
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      {composeProviders([], <RouterProvider router={router} />)}
    </Sentry.ErrorBoundary>
  );
};
