import { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { Loader } from './tma/domain/components/Loader';

const HomePage = lazy(() =>
  import('./home/HomePage').then((module) => ({
    default: module.HomePage,
  })),
);

const TMALayout = lazy(() =>
  import('./tma/TMALayout').then((module) => ({
    default: module.TMALayout,
  })),
);

const GiveawayLayout = lazy(() =>
  import('./tma/tokens/GeneratorLayout').then((module) => ({
    default: module.GeneratorLayout,
  })),
);

// const WelcomePage = lazy(() =>
//   import('./tma/tokens/welcome/WelcomePage').then((module) => ({
//     default: module.WelcomePage,
//   })),
// );

const GiveawayGameContainer = lazy(() =>
  import('./tma/tokens/generator/domain/containers/GiveawayGameContainer').then((module) => ({
    default: module.GiveawayGameContainer,
  })),
);

// const ProductsPage = lazy(() =>
//   import('./tma/tokens/products/ProductsPage').then((module) => ({
//     default: module.ProductsPage,
//   })),
// );

const DashboardPage = lazy(() =>
  import('./tma/tokens/dashboard/DashboardPage').then((module) => ({
    default: module.DashboardPage,
  })),
);

const ReferralsPage = lazy(() =>
  import('./tma/tokens/referrals/ReferralsPage').then((module) => ({
    default: module.ReferralsPage,
  })),
);

const TransactionsPage = lazy(() =>
  import('./tma/tokens/wallet/transactions/TransactionsPage').then((module) => ({
    default: module.TransactionsPage,
  })),
);

const AnalyticsContainer = lazy(() =>
  import('./domain/containers/AnalyticsContainer').then((module) => ({
    default: module.AnalyticsContainer,
  })),
);

const WalletPage = lazy(() =>
  import('./tma/tokens/wallet/WalletPage').then((module) => ({
    default: module.WalletPage,
  })),
);

export const router = createBrowserRouter([
  {
    path: '*',
    element: <AnalyticsContainer />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loader isFullScreen />}>
            <HomePage />
          </Suspense>
        ),
      },
      {
        path: 'tma/*',
        element: (
          <Suspense fallback={<Loader isFullScreen />}>
            <TMALayout />
          </Suspense>
        ),
        children: [
          {
            path: 'tokens/*',
            element: (
              <Suspense fallback={<Loader isFullScreen />}>
                <GiveawayLayout />
              </Suspense>
            ),
            children: [
              {
                path: 'welcome/*',
                element: (
                  <Suspense fallback={<Loader isFullScreen />}>
                    <DashboardPage />
                  </Suspense>
                ),
              },
              // {
              //   path: 'welcome_/*',
              //   element: (
              //     <Suspense fallback={<Loader isFullScreen />}>
              //       <WelcomePage />
              //     </Suspense>
              //   ),
              // },
              {
                path: 'game/*',
                element: (
                  <Suspense fallback={<Loader isFullScreen />}>
                    <GiveawayGameContainer />
                  </Suspense>
                ),
              },
              // {
              //   path: 'products/*',
              //   element: (
              //     <Suspense fallback={<Loader isFullScreen />}>
              //       <ProductsPage />
              //     </Suspense>
              //   ),
              // },
              {
                path: 'referrals/*',
                element: (
                  <Suspense fallback={<Loader isFullScreen />}>
                    <ReferralsPage />
                  </Suspense>
                ),
              },
              {
                path: 'wallet/*',
                children: [
                  {
                    index: true,
                    element: (
                      <Suspense fallback={<Loader isFullScreen />}>
                        <WalletPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'transactions/*',
                    element: (
                      <Suspense fallback={<Loader isFullScreen />}>
                        <TransactionsPage />
                      </Suspense>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <div>not found</div>,
      },
    ],
  },
]);
